const dev = {
  url: 'http://localhost:8000/api'
}

const prod = {
  //development_variable
  // url: 'https://adrmediationtest.fultoncourt.org/api'
  //production_variable
  url: 'https://api.adrmediation.fultoncourt.org/api'
}

const _prod = {
  url: 'https://api.adrmediation.fultoncourt.org/api'
}

const local_storage = {
  JWT_ACCESS_KEY: "nErTorENDectaiND",
  EXPIRES_IN: "eadIamEntoSInetH",
  USER_ID: "nlasdfALSERNSDln",
  NAME: "adhaoUkHLJLKALas",
  ROLE: "werHOJalsdfHOASD",
  IS_PASSWORD_SET: "JalENTectaERNvin",
  IS_ACTIVE: "asdfOHlJHL23sdfs",
  ENVIRONMENT: "PRODUCTION"
}

// let session_url = window.location.href;
// const config = session_url.includes("https://adrmediationfrontend.fultoncourt.org/") ? "dev" : "prod";

// console.log("env is "+config);

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default {
  ...config,
  ...local_storage
}